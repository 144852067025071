import React from "react";
import styles from "./privacy.module.scss";
import { Navbar } from "../../components";

//privacy policy page
export function Privacy({ isOpen, setIsOpen, setIsLoding }) {
  return (
    <div className={styles.about}>
      <Navbar isOpen={isOpen} setIsOpen={setIsOpen} setIsLoding={setIsLoding} />
      <div className={styles.about_page}>
        <div className={styles.aboutContainer}>
          <div className={styles.policy_title}>
            <div></div>
            <h2>PRIVACY POLICY</h2>
          </div>
          <div className={styles.secondBlock}>
            <div className={styles.desc}>
              <div className={styles.section_1}>
                <p>
                  This privacy notice for "AMRACODE" LLC, Yerevan, the Republic
                  of Armenia (doing business as Amracode) ('Amracode', 'we',
                  'us', or 'our'), describes how and why we might collect,
                  store, use, and/or share ('process') your information when you
                  iteract with our website and marketing initiatives (Services):
                </p>
                <p>
                  Questions or concerns? Reading this privacy notice will help
                  you understand your privacy rights and choices. If you do not
                  agree with our policies and practices, please do not use our
                  Services. If you still have any questions or concerns, please
                  contact us at privacy@amracode.com.
                </p>
              </div>
              <div className={styles.section_2}>
                <h4>Collection of information</h4>
                <p>
                  AMRACODE collects information to provide better services to
                  its users and to better understand the visitors to its
                  websites and what content is of interest to them. AMRACODE
                  collects information in the following ways:
                </p>
                <ul>
                  <li>
                    {" "}
                    Information you affirmatively choose to submit to us (such
                    as your full name, email address, company name, phone
                    number) for the purpose of viewing specific content,
                    subscribing to AMRACODE newsletters, asking for a demo or
                    call and your preferences in receiving marketing from
                    AMRACODE and its third-party partners and your communication
                    preferences, etc. Information we have obtained about you
                    from publicly available sources such as social media,
                    marketing platforms or on events you attended. You will
                    receive marketing communications from AMRACODE if you have
                    requested information from AMRACODE or you or your employer
                    has purchased services from AMRACODE.{" "}
                  </li>
                  <li>
                    {" "}
                    Information we receive from your use of AMRACODE website,
                    such as the IP address, browser type, ISP, referring/exit
                    pages, platform type, date/time stamp, number of clicks,
                    domain name and country/state of all visitors to AMRACODE
                    website.{" "}
                  </li>
                  <li>
                    {" "}
                    Information we receive from your use of AMRACODE website,
                    such as the IP address, browser type, ISP, referring/exit
                    pages, platform type, date/time stamp, number of clicks,
                    domain name and country/state of all visitors to AMRACODE
                    website.C{" "}
                  </li>
                </ul>
              </div>
              <div className={styles.section_3}>
                <p>
                  Information that is considered aggregated data such as
                  statistical or demographic data which AMRACODE may use for any
                  purpose. Aggregated data may be derived from your personal
                  information but is not considered personal information in law
                  as it does not directly or indirectly reveal your
                </p>
                <ul style={{ listStyleType: "disc" }}>
                  <li>
                    identity, unless AMRACODE combines or connects aggregated
                    data with your personal information so that it can directly
                    or indirectly identify you; and
                  </li>
                  <li>
                    AMRACODE collects technical data from various third parties
                    and public sources as, such as:
                  </li>
                </ul>
                <ul style={{ listStyleType: "circle" }}>
                  <li> analytics providers</li>
                  <li>advertising networks</li>
                  <li>search information providers</li>
                </ul>
              </div>
              <div className={styles.section_4}>
                <p>
                  Other than as required by law, AMRACODE websites do not
                  collect any special categories of personal information about
                  you (this includes details about your race or ethnicity,
                  religious or philosophical beliefs, sex life, sexual
                  orientation, political opinions, trade union membership,
                  information about your health and genetic and biometric data).
                  AMRACODE will not collect any information about criminal
                  convictions and offenses.
                </p>
                <p>
                  AMRACODE will only use your personal information for the
                  purposes for which it was collected, unless it reasonably
                  considers that it needs to use it for another reason that is
                  compatible with the original purpose. If AMRACODE needs to use
                  your personal information for an unrelated purpose, AMRACODE
                  will notify you and explain the legal basis which allows
                  AMRACODE to do so.
                </p>
              </div>
              <div className={styles.section_5}>
                <h4>Sharing of Information</h4>
                <p>
                  AMRACODE may disclose your personal information to the
                  following categories of recipients:
                </p>
                <ul>
                  <li>
                    to its{" "}
                    <span>
                      group companies, professional advisers, third party
                      services providers and partners
                    </span>{" "}
                    who provide data processing services to AMRACODE (for
                    example, to support the delivery of, provide functionality
                    on, or help to enhance the security of AMRACODE websites),
                    or who otherwise process personal information for purposes
                    that are described in Privacy Notices or notified to you
                    when AMRACODE collects your personal information. A list of
                    AMRACODE's current group companies is available upon
                    request.
                  </li>
                  <li>
                    to any{" "}
                    <span>
                      competent law enforcement body, regulatory, government
                      agency, court or other third party
                    </span>{" "}
                    where AMRACODE believes disclosure is necessary (a) as a
                    matter of applicable law or regulation, (b) to exercise,
                    establish or defend its legal rights, or (c) to protect your
                    vital interests or those of any other person.
                  </li>
                  <li>
                    to any <span>other person with your consent</span> to the
                    disclosure.
                  </li>
                </ul>
              </div>
              <div className={styles.section_6}>
                <h4>Use of Information</h4>
                <p>
                  Any information collected by AMRACODE will be done in
                  accordance with this Privacy Policy for the purposes of making
                  better business decisions, supporting the activities you
                  choose to engage in, and providing higher quality solutions
                  and services to AMRACODE's customers. AMRACODE uses your
                  location information to tailor your experience with AMRACODE
                  websites. It may also use any non-personal information
                  collected to enhance AMRACODE websites, and your IP address
                  for website diagnostics and administration. AMRACODE will only
                  retain your personal information for as long as necessary to
                  fulfill the purposes it was collected for, including for the
                  purposes of satisfying any legal, accounting, or reporting
                  requirements.
                </p>
                <p>
                  To determine the appropriate retention period for personal
                  information, AMRACODE considers the amount, nature, and
                  sensitivity of the personal information, the potential risk of
                  harm from unauthorised use or disclosure of your personal
                  information, the purposes for which AMRACODE processes your
                  personal information and whether it can achieve those purposes
                  through other means, and the applicable legal requirements.
                </p>
              </div>
              <div className={styles.section_7}>
                <h4>Information Security</h4>
                <p>
                  AMRACODE protects your personal information in the same way
                  that it protects its other confidential information, such as
                  storing it on secure servers that only select AMRACODE
                  personnel have access to, and by encrypting it to help prevent
                  access by any unauthorized parties. AMRACODE has put in place
                  procedures to deal with any personal information breach and
                  will notify you and any applicable regulator of a breach when
                  legally required to do so.
                </p>
              </div>
              <div className={styles.section_8}>
                <h4>Marketing</h4>
                <p>
                  As explained above, AMRACODE will collect and use your
                  personal data for marketing purposes. Your data will be stored
                  in our CRM system. We will use your data to inform you about
                  AMRACODE products and services we think may be of your
                  interest and send you our newsletters. For statistical
                  purposes, we will collect information about your response to
                  our emails and our interaction with you to make decisions on
                  future marketing. If you wish to stop us from collecting and
                  using this information please contact privacy@amracode.com.
                </p>
              </div>
              <div className={styles.section_9}>
                <h4>Your Data Protection Rights</h4>
                <p>
                  You have the following data protection rights which you may be
                  able to exercise by contacting AMRACODE using
                  privacy@amracode.com.
                </p>
                <ul>
                  <li>
                    {" "}
                    If you wish to <span>request deletion</span> of your
                    personal information, you can do that in the Settings of
                    your profile on Profile.Amracode.com
                  </li>
                  <li>
                    {" "}
                    If you wish to <span>access, correct, update</span> your
                    personal information, you can do so at any time by
                    contacting AMRACODE
                  </li>
                  <li>
                    In addition, you can <span>object to processing</span> of
                    your personal information, ask AMRACODE to{" "}
                    <span>restrict processing</span> of your personal
                    information or <span>request portability</span> of your
                    personal information by contacting AMRACODE.
                  </li>
                  <li>
                    You have the right to{" "}
                    <span>opt-out of marketing communications</span>
                    that AMRACODE sends you at any time. You can exercise this
                    right by writing us to privacy@amracode.com.
                  </li>
                  <li>
                    Similarly, if AMRACODE has collected and processes your
                    personal information based on your consent, then you can
                    <span> withdraw your consent</span> at any time. Withdrawing
                    your consent will not affect the lawfulness of any
                    processing AMRACODE conducted prior to your withdrawal, nor
                    will it affect processing of your personal information
                    conducted in reliance on lawful processing grounds other
                    than consent.
                  </li>
                  <li>
                    You have the <span> right to complain to a data protection authority </span>
                    about AMRACODE's collection and use of your personal
                    information. For more information, please contact your local
                    data protection authority.
                  </li>
                </ul>
                <p>
                  You will not have to pay a fee to access your personal
                  information (or to exercise any of the other rights). However,
                  AMRACODE may charge a reasonable fee or refuse to comply with
                  your request if your request is clearly unfounded, repetitive
                  or excessive. AMRACODE tries to respond to all legitimate
                  requests within one month. Occasionally, it may take longer if
                  your request is particularly complex or you have made a number
                  of requests. In this case, AMRACODE will notify you and keep
                  you updated.
                </p>
              </div>

              <div className={styles.policy_title}>
                <div></div>
                <h2>COOKIE POLICY</h2>
              </div>
              <div className={styles.section_10}>
                <p>
                  AMRACODE LLC provides this Cookie Policy, which applies to any
                  websites, branded pages on third party platforms, and
                  applications accessed or used through such websites or third
                  party platforms (“Amracode Sites”), which are operated by or
                  on behalf of AMRACODE. 
                </p>
                <p>
                  When you visit the AMRACODE Sites, AMRACODE uses a number of
                  suppliers who may place cookies, tags, pixels, and similar
                  tracking technologies (collectively, “Cookies”) on your
                  device, browser, or the webpage you are viewing, in order to
                  personalize your experience (e.g., language preferences),
                  understand usage patterns, provide, improve and secure the
                  AMRACODE Sites. 
                </p>
                <p>
                  AMRACODE Sites use different types of Cookies for different
                  reasons, as follows: 
                </p>
              </div>
              <div className={styles.section_7}>
                <h4>Essential cookies</h4>
                <p>
                  Essential cookies and scripts are essential for our website to
                  function. They allow you to move around our website and use
                  its basic features, such as accessing secure areas of the
                  website, opening navigation, and displaying content.
                </p>
              </div>
              <div className={styles.section_7}>
                <h4>Analytics</h4>
                <p>
                  Analytics allow us to count visits and traffic sources to the
                  website, so that we can measure and improve the performance of
                  our site. Analytics let us know which pages are the most and
                  least popular and see how visitors move around the site. All
                  of the information collected from analytics cookies is
                  aggregated so it is anonymous.
                </p>
              </div>
              <div className={styles.section_7}>
                <h4>Marketing</h4>
                <p>
                  These items are used to deliver advertising that is more
                  relevant to you and your interests. They may also be used to
                  limit the number of times you see an advertisement and measure
                  the effectiveness of advertising campaigns. We use only a few
                  third party cookies. We use Facebook, LinkedIn, and Google's
                  pixels to track our marketing efforts.
                </p>
              </div>
              <div className={styles.section_7}>
                <h4>Personalization</h4>
                <p>
                  These items allow the website to remember choices you make
                  (such as your name, language, or the region you are in) and
                  provide enhanced, more personal features. For example, a
                  website may provide you with local weather reports or traffic
                  news by storing data about your current location.
                </p>
              </div>
              <div className={styles.section_11}>
                <h4>Cookies We May Use</h4>
                <div>
                  {" "}
                  <table>
                    <tbody>
                      <tr>
                        <th>Name</th>
                        <th>Source</th>
                        <th>Purpose</th>
                        <th>Expiration</th>
                      </tr>
                      <tr>
                        <td>__cfduid</td>
                        <td>CloudFlare Inc</td>
                        <td>Essential</td>
                        <td>1 Year</td>
                      </tr>
                      <tr>
                        <td>__cfruid</td>
                        <td>CloudFlare Inc</td>
                        <td>Essential</td>
                        <td>Session</td>
                      </tr>
                      <tr>
                        <td>__hluid</td>
                        <td>Hubspot Inc</td>
                        <td>Essential</td>
                        <td>1 Year</td>
                      </tr>
                      <tr>
                        <td>__hmpl</td>
                        <td>Hubspot Inc</td>
                        <td>Essential</td>
                        <td>1 Year</td>
                      </tr>
                      <tr>
                        <td>__hssrc</td>
                        <td>Hubspot Inc</td>
                        <td>Analytics</td>
                        <td>1 Year</td>
                      </tr>
                      <tr>
                        <td>__hstc</td>
                        <td>Hubspot Inc</td>
                        <td>Analytics</td>
                        <td>1 Year</td>
                      </tr>
                      <tr>
                        <td>__fbp</td>
                        <td>Facebook Inc</td>
                        <td>Marketing</td>
                        <td>90 days</td>
                      </tr>
                      <tr>
                        <td>__hssc</td>
                        <td>Hubspot Inc</td>
                        <td>Analytics</td>
                        <td>1 Year</td>
                      </tr>
                      <tr>
                        <td>_pk_id.3.f092</td>
                        <td>Osano Inc</td>
                        <td>Analytics</td>
                        <td>395 Days</td>
                      </tr>
                      <tr>
                        <td>_pk_ses.3.f092</td>
                        <td>Osano</td>
                        <td>Analytics</td>
                        <td>Session</td>
                      </tr>
                      <tr>
                        <td>_wn_last</td>
                        <td>Osano</td>
                        <td>Personalization</td>
                        <td>1 Year</td>
                      </tr>
                      <tr>
                        <td>
                          ably-transport <br /> -preference
                        </td>
                        <td>Hubspot Inc</td>
                        <td>Essential</td>
                        <td>Session</td>
                      </tr>
                      <tr>
                        <td>
                          hs-messages-hide
                          <br />
                          -welcome-message
                        </td>
                        <td>Hubspot Inc</td>
                        <td>Essential</td>
                        <td>Session</td>
                      </tr>
                      <tr>
                        <td>
                          hs-messages
                          <br />
                          -is-open
                        </td>
                        <td>Hubspot Inc</td>
                        <td>Essential</td>
                        <td>Session</td>
                      </tr>
                      <tr>
                        <td>
                          HUBLYTICS_
                          <br />
                          EVENTS_53
                        </td>
                        <td>Hubspot Inc</td>
                        <td>Analytics</td>
                        <td>Session</td>
                      </tr>
                      <tr>
                        <td>hubspotutk</td>
                        <td>Hubspot Inc</td>
                        <td>Analytics</td>
                        <td>395 Days</td>
                      </tr>
                      <tr>
                        <td>
                          i18n-chaned
                          <br />
                          -public-locales
                        </td>
                        <td>Hubspot Inc</td>
                        <td>Essential</td>
                        <td>Session</td>
                      </tr>
                      <tr>
                        <td>IDE</td>
                        <td>Google LLC</td>
                        <td>Marketing</td>
                        <td>395 Days</td>
                      </tr>
                      <tr>
                        <td>JSESSIONID</td>
                        <td>Hubspot Inc</td>
                        <td>Essential</td>
                        <td>Session</td>
                      </tr>
                      <tr>
                        <td>li_sugr</td>
                        <td>Linkedin</td>
                        <td>Marketing</td>
                        <td>90 Days</td>
                      </tr>
                      <tr>
                        <td>lidc</td>
                        <td>Linkedin</td>
                        <td>Marketing</td>
                        <td>1 Days</td>
                      </tr>
                      <tr>
                        <td>messagesUtk</td>
                        <td>Osano</td>
                        <td>Essential</td>
                        <td>1 year</td>
                      </tr>
                      <tr>
                        <td>
                          osano_consent
                          <br />
                          manager
                        </td>
                        <td>Osano</td>
                        <td>Essential</td>
                        <td>1 year</td>
                      </tr>
                      <tr>
                        <td>
                          osano_consent
                          <br />
                          manager_expdate
                        </td>
                        <td>Osano</td>
                        <td>Essential</td>
                        <td>1 year</td>
                      </tr>
                      <tr>
                        <td>
                          osano_consent
                          <br />
                          manager_uuid
                        </td>
                        <td>Osano</td>
                        <td>Essential</td>
                        <td>1 year</td>
                      </tr>
                      <tr>
                        <td>UserMatchHistory</td>
                        <td>Linkedin</td>
                        <td>Marketing</td>
                        <td>1 Year</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <p className={styles.section_7}>
                If you do not agree to certain uses of Cookies detailed above,
                you can exercise certain choices through your browser settings
                or by not using the AMRACODE Sites. If you choose to reject
                cookies via your browser settings, you may still use AMRACODE
                Sites though your access to some functionality and areas may be
                restricted. As the means by which you can refuse cookies through
                your web browser controls vary from browser-to-browser, you
                should visit your browser’s help menu for more information.
              </p>
              <div className={styles.section_7}>
                <h4>Changes to the Policy</h4>
                <p>
                  AMRACODE reserves the right to modify or amend this Privacy
                  Policy at its own discretion from time to time. The changes
                  can be viewed here or elsewhere on AMRACODE websites. Your
                  continued use of AMRACODE websites constitutes your acceptance
                  of those changes.
                </p>
                <p>
                  This version was last updated on 30 May 2023 and historic
                  versions can be obtained by contacting AMRACODE.
                </p>
              </div>
              <div className={styles.section_7}>
                <h4>Contact Details</h4>
                <p>
                  It is important that the personal information AMRACODE holds
                  about you is accurate and current. Please keep AMRACODE
                  informed if your personal information changes during your
                  relationship with AMRACODE. Additionally, if you have any
                  questions about this Privacy Policy, the practices of AMRACODE
                  websites or your interaction with AMRACODE via AMRACODE
                  websites, please contact:
                </p>
              </div>
              <div className={styles.section_12}>
                <h4>AMRACODE, Inc.</h4>
                <p>70/3 Dzorapi Str., Yerevan, Armenia</p>
                <p>P: +37455 665 175</p>
                <p>E: privacy@amracode.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
