import React from "react";
import s from "./advertising.module.scss";
import { Link } from "react-router-dom";


//Component for header advertising

export const Advertising = () => {
  return (
    <div className={s.adversting}>
      <p>Անվճար ՏՏ խորհրդատվություն</p>
      <button aria-label="button">
        <Link className={s.link} to="/free-it-consulting" target="_blank">Դիմել հիմա</Link>
      </button>
    </div>
  );
};
