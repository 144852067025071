import React, { useState } from "react";
import styles from "./dropdown.module.scss";
import arrowIcon from "./../../assets/svg/arrow.svg";
import { useTranslation } from "react-i18next";


//Component dropdown for languages
export const DropDown = ({ isOpen, setIsOpen }) => {
  const [t, i18n] = useTranslation("common");

  const countries = [
    {
      option: "ENG",
      lang: "en",
    },
    {
      option: "РУС",
      lang: "ru",
    },
    {
      option: "ՀԱՅ",
      lang: "am",
    },
  ];

  const language = localStorage.getItem("language") || i18n.language;
  const findLanguageByStorage = countries.findIndex(
    (item) => item.lang === language
  );
  const [value, setValue] = useState(
    countries[findLanguageByStorage === -1 ? 0 : findLanguageByStorage].option
  );
  const toggling = () => setIsOpen(!isOpen);
console.log(i18n.language,"Ddfd");
  return (
    <div className={styles.main} onClick={(e) => e.stopPropagation()}>
      <div className={styles.dropDownHeader} onClick={toggling}>
        <p
          style={{
            fontFamily:
              (value === "ENG" && "Orbitron") || (value === "РУС" && "Zekton"),
          }}
        >
          {value}
        </p>
        <img
          alt="arrow"
          src={arrowIcon}
          className={`${isOpen && styles.arrowActive} ${styles.arrowIcon}`}
        />
      </div>

      <div
        className={`${isOpen && styles.active} ${styles.dropDownListContainer}`}
      >
        <ul className={styles.dropDownList}>
          {countries.map(
            (item, index) =>
              item.option !== value && (
                <li
                  style={{
                    fontFamily:
                      (item.option === "ENG" && "Orbitron") ||
                      (item.option === "РУС" && "Zekton"),
                  }}
                  key={index}
                  className={styles.listItem}
                  onClick={() => {
                    if (isOpen) {
                      i18n.changeLanguage(item.lang);
                      localStorage.setItem("language", item.lang);
                      setValue(item.option);
                      setIsOpen(false);
                    }
                  }}
                >
                  {item.option}
                </li>
              )
          )}
        </ul>
      </div>
    </div>
  );
};
