import React, { useEffect } from "react";

//integration hubspot service contact form for apply now

export function HubspotForm(props) {

  const { region, portalId, formId } = props;
  useEffect(() => {
    const timeout = setTimeout(() => {
      const script = document.createElement("script");
      script.setAttribute("rel", "preconnect");
      script.setAttribute("crossorigin", "");
      script.src = "https://js.hsforms.net/forms/embed/v2.js";
      script.async = true;
      document.body.appendChild(script);

      script.addEventListener("load", () => {
        // @TS-ignore
        if (window.hbspt) {
          // @TS-ignore
          window.hbspt.forms.create({
            region: region,
            portalId: portalId,
            formId: formId,
            target: "#hubspotContactForm",
          });
        }
      });
    }, 2500);

    return () => {
      clearTimeout(timeout); // Очистка таймера при размонтировании компонента
    };
  }, [region, portalId, formId]);

  return (
    <div>
      <div id="hubspotContactForm"></div>
    </div>
  );
}
