import React from "react";
import { Carousel, Navbar } from "../../components";
import styles from "./../../components/carousel/service.module.scss";

//page for our services
export function OurServicesPage({ isOpen, setIsOpen, setIsLoding }) {
  return (
    <>
      <div className={styles.ourService}>
        <Navbar
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setIsLoding={setIsLoding}
        />
        <Carousel />
      </div>
    </>
  );
}
