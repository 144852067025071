import React from "react";
import styles from "./index.module.css";
import { HubspotContactForm } from "./../../hubspotContactForm/hubspotContactForm";

export const Modal = ({ openModal, setOpenModal, screenHeight }) => {
  const closeModal = () => {
    setOpenModal(false);
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <div
      style={{ height: screenHeight }}
      onClick={closeModal}
      className={`${styles.modal} ${openModal ? styles.show : ""}`}
    >
      <div className={styles.modal_div}>
        <div className={styles.modal_header} onClick={stopPropagation}>
          <div className={styles.title}>
            <span className={styles.modal_close} onClick={closeModal}>
              X
            </span>
          </div>
          <HubspotContactForm
            region="na1"
            portalId="22554199"
            formId="85f8ee2f-f6a5-4b42-8607-0c45dcf5480d"
          />
        </div>
      </div>
    </div>
  );
};
