import React from "react";
import styles from "./about.module.scss";
import aboutTitle from "./../../assets/svg/aboutTitle.svg";
import aboutTitle_Am from "./../../assets/svg/aboutTitle_Am.svg";
import aboutTitle_Ru from "./../../assets/svg/aboutTitle_Ru.svg";
import { useTranslation } from "react-i18next";
import { Navbar } from "../../components";

//About us page 
export function AboutUsPage({ isOpen, setIsOpen, setIsLoding }) {
  const [t, i18n] = useTranslation("common");
  const i18_Lang = i18n.language;

  return (
    <>
    <div className={styles.about}>
      <Navbar isOpen={isOpen} setIsOpen={setIsOpen} setIsLoding={setIsLoding} />
      <div className={styles.about_page}>
        <div className={styles.aboutContainer} id="container">
          <div className={styles.firstBlock}>
            <div className={styles[i18_Lang]}>
              <img src={aboutTitle} alt="" />
              <img src={aboutTitle_Am} alt="" />
              <img src={aboutTitle_Ru} alt="" />
            </div>
            <div className={styles[i18_Lang]}>
              <p>{t("aboutUs_firstText")}</p>
            </div>
          </div>
          <div className={styles.secondBlock}>
            <div className={styles.secondBlockTitle}>
              <h1 className={styles[i18_Lang]}>{t("aboutUs_shortText")}</h1>
              <div className={styles[i18_Lang]}></div>
            </div>
            <div className={styles[i18_Lang]}>
              <p>{t("aboutUs_longText")}</p>
              <p>{t("aboutUs_longtext_2")}</p>
              <p>{t("aboutUs_longtext_3")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}
