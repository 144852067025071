import React from "react";
import ReactDOM from "react-dom/client";
import "./global.scss";
import "./_variables.scss";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import AM from "./i18n/languages/am/index.json";
import RU from "./i18n/languages/ru/index.json";
import EN from "./i18n/languages/en/index.json";

i18next.init({
  interpolation: { escapeValue: false },
  lng: "en",
  resources: {
    am: {
      common: AM,
    },
    ru: {
      common: RU,
    },
    en: {
      common: EN,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <I18nextProvider i18n={i18next}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </I18nextProvider>
);
