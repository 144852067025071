import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./clients.module.scss";
import { Navbar } from "../../components";
import BannerImages from "../../components/bannerImages/BannerImages";

//page for clients 
export const ClientsPage = ({ isOpen, setIsOpen, setIsLoding }) => {
  const [t, i18n] = useTranslation("common");
  const i18_Lang = i18n.language;

  return (
    <>
     <div className={styles.partners}>
      <Navbar isOpen={isOpen} setIsOpen={setIsOpen} setIsLoding={setIsLoding} />
      <div className={styles.partners_page}>
        <div className={styles[i18_Lang]}>
          <p>{t("clients_trustedBy")}</p>
          <p dangerouslySetInnerHTML={{ __html: `${t("clients_text")}` }} />
        </div>
        <div className={styles.partners_App}>
          <BannerImages />
        </div>
      </div>
    </div></>
   
  );
};
