import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./service.module.scss";
import nextimg from "./../../assets/svg/Arrow_right.svg";
import previmg from "./../../assets/svg/Arrow_right.svg";


//Carousel for services page
export const Carousel = () => {
  const [t, i18n] = useTranslation("common");
  const i18_Lang = i18n.language;

  const our_service = [
    { path: "" },
    { path: "services_text_1" },
    { path: "services_text_2" },
    { path: "services_text_3" },
    { path: "services_text_4" },
    { path: "services_text_5" },
    { path: "services_text_6" },
    { path: "services_text_7" },
  ];

  const [carouselDeg, setCarouselDeg] = useState(0);
  const [starDeg, setStarDeg] = useState(0);
  const [carouselCount, setCarouselCount] = useState([1, 2, 3, 4, 5, 6, 7]);
  const [count, setCount] = useState(1);
  const [carousel, setCarousel] = useState(our_service);
  const [selected, setSelected] = useState(false);
  const [selectedPrev, setSelectedPrev] = useState(false);
  const [nextDisabled, setNextDisabled] = useState(false);

  const next = (nextItem) => {
    setSelected(true);
    if (nextItem === 1) {
      return;
    }
    setTimeout(() => {
      setCarousel((prevCarousel) => [...prevCarousel.slice(1), prevCarousel[0]]);
      setCount((prevCount) => prevCount + 1);
      setSelected(false);
    }, 300);
    setCarouselDeg((prevCarouselDeg) => prevCarouselDeg - 45);
    setStarDeg((prevStarDeg) => prevStarDeg - 360);
    if (count === carousel.length - 2) {
      setNextDisabled(true);
    }
  };

  const prev = () => {
    setSelectedPrev(true);

    if (carouselDeg === 0) {
      return "finish";
    }
    setTimeout(() => {
      setCarousel((prevCarousel) => [prevCarousel[prevCarousel.length - 1], ...prevCarousel.slice(0, prevCarousel.length - 1)]);
      setCount((prevCount) => prevCount - 1);
      setSelectedPrev(false);
    }, 300);
    setNextDisabled(false);
    setStarDeg((prevStarDeg) => prevStarDeg + 360);
    setCarouselDeg((prevCarouselDeg) => prevCarouselDeg + 45);
  };

  return (
    <>
      {count !== 1 && (
        <button
          onClick={prev}
          className={styles.button_prev}
          disabled={selectedPrev}
        >
          <img src={previmg} alt="prev" />
        </button>
      )}
      {!nextDisabled && (
        <button
          onClick={() => next(count + 1)}
          className={styles.button_next}
          disabled={nextDisabled || selected}
        >
          <img src={nextimg} alt="next" />
        </button>
      )}
      <div className={styles.container}>
        <div
          className={`${styles.card_wrapper} ${styles[i18_Lang]} ${
            selected && styles.transition
          } ${selected && styles.offset} ${
            selectedPrev && styles.transition_right
          } ${selectedPrev && styles.offset_right}`}
        >
          {carousel.slice(0, 3).map((item, index) => (
            <div
              key={index}
              className={`${styles.single_card} ${
                index === 1 && styles.title_active
              }`}
            >
              <div className={`${index === 1 && styles.active_width}`}>
                {t(item?.path)}
              </div>
            </div>
          ))}
          <div className={styles.border_style}></div>
        </div>
      </div>

      <div className={styles.App}>
        <div
          style={{ transform: `rotate(${starDeg}deg)` }}
          className={styles.carousel}
        >
          {carousel.map((_, i) => (
            <div key={i} className={`${styles.star} ${styles[`star${++i}`]}`} />
          ))}
        </div>
        <div
          className={styles.carousel_1}
          style={{ transform: `rotate(${carouselDeg}deg)` }}
        >
          {carouselCount.map((item, index) => {
            let classNumber = item === carousel.length ? 8 : +index + 1;
            return (
              <button
                key={index}
                onClick={() => {
                  if (count === 1 && item === 2) {
                    next(item);
                  } else if (item <= count) {
                    if (count !== item) {
                      prev(item);
                    }
                  } else if (item >= count) {
                    next(item);
                  } else {
                    console.log("wrong");
                  }
                }}
                disabled={selected || selectedPrev}
                className={`${styles.num} ${styles[`num${classNumber}`]} ${
                  item === 1 && styles.firstNumPosition
                } ${
                  count > 1 && styles[`numberPosition${classNumber}`]
                } ${
                  (carouselDeg === +index * 45 ||
                    carouselDeg === -Number(index) * 45) &&
                  styles.carouselItemActive
                } ${styles.carouselItem}`}
              >
                {item}
              </button>
            );
          })}
        </div>
      </div>
    </>
  );
};
