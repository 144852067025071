import React from "react";
import styles from "./financing.module.scss";
import { Navbar } from "../../components";
import { HubspotForm } from "../../hubspotForm/hubspotForm";

//page for co-financing program 
export function Financing({ isOpen, setIsOpen, setIsLoding }) {
  return (
    <div className={styles.about}>
      <Navbar isOpen={isOpen} setIsOpen={setIsOpen} setIsLoding={setIsLoding} />
      <div className={styles.about_page}>
        <div className={styles.aboutContainer}>
          <HubspotForm
            region="na1"
            portalId="22554199"
            formId="f902d752-a8cd-4b5b-9c94-97d1b53e0dda"
          />
        </div>
      </div>
    </div>
  );
}
