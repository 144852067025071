import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router";
import {
  HomePage,
  OurServicesPage,
  AboutUsPage,
  ClientsPage,
  Privacy,
  Financing,
} from "../pages";

export default function PageRoutes({
  isOpen,
  setIsOpen,
  openModal,
  setOpenModal,
  screenHeight,
  setIsLoding,
}) {
  const location = useLocation();
  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransitionStage] = useState("fadeIn");

  useEffect(() => {
    if (location !== displayLocation) setTransitionStage("fadeOut");
  }, [displayLocation, location]);

  const homeComponent = (
    <HomePage
      {...{ isOpen, setIsOpen, openModal, setOpenModal, setIsLoding }}
    />
  );

  const aboutUsComponent = (
    <AboutUsPage {...{ isOpen, setIsOpen, screenHeight, setIsLoding }} />
  );

  const ourServicesComponent = (
    <OurServicesPage {...{ isOpen, setIsOpen, setIsLoding }} />
  );

  const clientsComponent = (
    <ClientsPage {...{ isOpen, setIsOpen, setIsLoding }} />
  );

  const privacyComponent = <Privacy {...{ isOpen, setIsOpen, setIsLoding }} />;

  const financingComponent = (
    <Financing {...{ isOpen, setIsOpen, setIsLoding }} />
  );

  return (
    <div
      className={`${transitionStage}`}
      onAnimationEnd={() => {
        if (transitionStage === "fadeOut") {
          setTransitionStage("fadeIn");
          setDisplayLocation(location);
        }
      }}
    >
      <Routes location={displayLocation}>
        <Route path="/" element={homeComponent} />
        <Route path="/aboutus" element={aboutUsComponent} />
        <Route path="/ourservices" element={ourServicesComponent} />
        <Route path="/clients" element={clientsComponent} />
        <Route path="/privacy" element={privacyComponent} />
        <Route path="/free-it-consulting" element={financingComponent} />
      </Routes>
    </div>
  );
}
