import React from "react";
import styles from "./button.module.scss";
import { useTranslation } from "react-i18next";
import "./../../../global.scss";

export function Button({ children, ...props }) {
  const [t, i18n] = useTranslation("common");
  const i18_Lang = i18n.language;

  return (
    <button {...props} className={styles[i18_Lang]} aria-label="button">
      {children}
    </button>
  );
}
