import React from "react";
import styles from "./home.module.scss";
import { useTranslation } from "react-i18next";
import { Animation, Navbar } from "./../../components";
import { Button } from "./../../components";

//home page 
export function HomePage({ isOpen, setIsOpen, setOpenModal ,setIsLoding }) {
  const [t, i18n] = useTranslation("common");
  const toggle = () => {
    setOpenModal(true);
  };

  const i18_Lang = i18n.language;

  return (
    <>
      <div className={styles.home} >
        <Navbar isOpen={isOpen} setIsOpen={setIsOpen} setIsLoding={setIsLoding} />
        <div style={{ position: "relative" }} className={styles.title_header}>
          <div className={styles[i18_Lang]}>
            <span className={styles.firstTitle}>
              {t("home_mainText_firstSpan")}
            </span>
            <span>{t("home_mainText_secondSpan")}</span>
            <span className={styles.lastTitle}>
              {t("home_mainText_thirdSpan")}
            </span>
            <span>{t("home_mainText_fourthSpan")}</span>
          </div>
        </div>
        <div className={styles.homeDivBtn}>
          <Button onClick={toggle}>{t("home_requestCallButton")}</Button>
          <Animation />
        </div>
      </div>
    </>
  );
}
