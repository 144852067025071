import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import styles from "./navbar.module.scss";
import logo from "./../../assets/svg/logo.svg";
import { DropDown } from "../dropdown";
import { Footer } from "../footer";
import { Advertising } from "../advertising";

//Global navbar compnent for the whole project

export const Navbar = ({ padding, isOpen, setIsOpen, setIsLoding }) => {
  const [t, i18n] = useTranslation("common");
  const [click, setClick] = useState(false);
  const [isOn, setIsOn] = useState(false);

  const handleClick = () => {
    setIsOn(!isOn);
    setClick(!click);
    setIsOpen(false);
  };
  
  const close = () => setClick(false);
  const location = useLocation();
  const i18_Lang = i18n.language;

  return (
   <>
    <Advertising />
    <div className={click ? styles.hamburger : ""}>
      <div className={styles.hamburgerBack_bg}></div>
      <nav
        className={styles.navbar}
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(false);
        }}
      >
        <div className={`${styles.nav} ${styles.nav_container}`}>
          <div className={styles.navLogo}>
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>
          </div>
          <div className={click ? styles.hamburgerBack_top : ""}></div>
          <div
            className={click ? styles.hamburgerBack : ""}
            onClick={handleClick}
          >
            <div
              className={
                click ? `${styles.nav_menu} ${styles.active}` : styles.nav_menu
              }
              onClick={(e) => {
                e.stopPropagation();
                setIsOpen(false);
              }}
            >
              <div className={styles[i18_Lang]}>
                <div className={`${styles.nav_item}`}>
                  <Link
                    to="/ourservices"
                    className={`${styles.nav_links} ${
                      location.pathname === "/ourservices" &&
                      styles.active_border
                    }`}
                    onClick={() => {
                      if (click) {
                        handleClick();
                      } else {
                        return null;
                      }
                    }}
                  >
                    {t("navbar_ourServices")}
                  </Link>
                </div>
                <div className={`${styles.nav_item}`}>
                  <Link
                    to="/aboutus"
                    className={`${styles.nav_links} ${
                      location.pathname === "/aboutus" && styles.active_border
                    }`}
                    onClick={() => {
                      if (click) {
                        handleClick();
                      } else {
                        return null;
                      }
                    }}
                  >
                    {t("navbar_aboutUs")}
                  </Link>
                </div>
                <div className={`${styles.nav_item}`}>
                  <Link
                    to="/clients"
                    className={`${styles.nav_links} ${
                      location.pathname === "/clients" && styles.active_border
                    }`}
                    onClick={() => {
                      if (click) {
                        handleClick();
                      } else {
                        return null;
                      }
                    }}
                    dangerouslySetInnerHTML={{
                      __html: `${t("navbar_clients")}`,
                    }}
                  ></Link>
                </div>
                <div className={`${styles.nav_item} ${styles.nav_footer}`}>
                  <Footer />
                </div>
              </div>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  setIsOpen(false);
                }}
              >
                <div>
                  <ul>
                    <li onClick={(e) => e.stopPropagation()}>
                      <DropDown
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        setIsLoding={setIsLoding}
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "none",
              justifyContent: "center",
              alignItems: "center",
            }}
            className={styles.langMobile}
          >
            <div className={styles.nav_icon}>
              <button
                className={isOn ? `${styles.on} ${styles.btn}` : styles.btn}
                onClick={handleClick}
                aria-label="button"
              >
                <span></span>
                <span></span>
              </button>
            </div>
          </div>
        </div>
      </nav>
    </div>
   </>
  );
};
