import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./footer.module.scss";
import fb from "./../../assets/svg/facebook-fill.svg";
import insta from "./../../assets/svg/mdi_instagram.svg";
import linkdin from "./../../assets/svg/linkedin-fill.svg";

//Global footer component
export const Footer = () => {
  const [t, i18n] = useTranslation("common");
  const location = useLocation();
  const i18_Lang = i18n.language;

  return (
    <div
      className={styles.footer}
      style={{ zIndex: location.pathname === "/ourservices" && "auto" }}
    >
      <div className={styles.background_bottom}></div>
      <div className={styles.footerIcon}>
        <ul>
          <li>
            <a
              href="https://www.instagram.com/amracode/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={insta} alt="Instagram" />
            </a>
          </li>
          <li>
            <a
              href="https://www.facebook.com/amracode"
              target="_blank"
              rel="noreferrer"
            >
              <img src={fb} alt="Facebook" />
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/amracode/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={linkdin} alt="LinkedIn" />
            </a>
          </li>
        </ul>
      </div>
      <div className={styles.footerLink}>
        <ul>
          <li className={styles[i18_Lang]}>
            <Link to="/privacy">Privacy Policy</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};
