import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Navbar, Footer, OsanoScript} from "./components";
import PageRoutes from "./routes/PageRoutes";
import loading from "./assets/svg/loading.svg";

function App() {
  const [openModal, setOpenModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoding, setIsLoding] = useState(true);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const { t, i18n } = useTranslation("common");
  const language = localStorage.getItem("language");

  useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.innerHeight);
    };

    i18n.changeLanguage(language);

    window.addEventListener("resize", handleResize);
    setTimeout(() => {
      setIsLoding(false);
    }, 3000);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [i18n, language]);

  const handleLayoutClick = () => {
    setIsOpen(false);
  };

  return (
    <>
      {isLoding && (
        <div className="loading" style={{ height: screenHeight }}>
          <img src={loading} alt="loading" width={80} height={80} />
          <div className="loading_div">
            <span>a</span>
            <span>a</span>
            <span>a</span>
            <span>a</span>
            <span>a</span>
            <span>a</span>
            <span>a</span>
          </div>
        </div>
      )}
      <div
        className="layout"
        style={{ height: screenHeight }}
        onClick={handleLayoutClick}
      >
        <Navbar
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setIsLoding={setIsLoding}
        />
        <PageRoutes
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          openModal={openModal}
          setOpenModal={setOpenModal}
          screenHeight={screenHeight}
          setIsLoding={setIsLoding}
        />
        <Modal
          openModal={openModal}
          setOpenModal={setOpenModal}
          screenHeight={screenHeight}
        />
        <Footer />
        <OsanoScript />
      </div>
    </>
  );
}

export default App;
