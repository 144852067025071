import React from "react";
import thomsonreuters from "./../../assets/img/cl-thomsonreuters.png";
import clvertical from "./../../assets/img/cl-360vertical.png";
import aderant from "./../../assets/img/cl-aderant.png";
import fei from "./../../assets/img/cl-fei.png";
import nvision from "./../../assets/img/cl-nvision.png";
import skov from "./../../assets/img/cl-skov.png";
import havaslynx from "./../../assets/img/cl-havaslynx.png";
import inpas from "./../../assets/img/cl-inpas.png";
import pharma from "./../../assets/img/cl-pharma.png";
import schlumberger_grey from "./../../assets/img/cl-schlumberger_grey.png";
import eucon from "./../../assets/img/cl-eucon.png";
import continental from "./../../assets/img/cl-continental.png";
import onlineresults from "./../../assets/img/cl-onlineresults.png";
import imagineer from "./../../assets/img/cl-imagineer.png";
import biosciences from "./../../assets/img/cl-biosciences.png";
import transaction_network from "./../../assets/img/cl-transaction_network.png";
import aspective from "./../../assets/img/cl-aspective.png";
import basecon from "./../../assets/img/cl-basecon.png";
import amity from "./../../assets/img/amity.png";
import dachser from "./../../assets/img/dachser.png";
import kogan from "./../../assets/img/kogan.png";
import didi from "./../../assets/img/didi.png";
import dr_redis from "./../../assets/img/dr_redis.png";
import komplett from "./../../assets/img/komplett.png";
import konga from "./../../assets/img/konga.png";
import AHG_Logo from "./../../assets/img/AHG_Logo.png";
import americanas from "./../../assets/img/americanas.png";
import apolo from "./../../assets/img/apolo.png";
import bfp_logo from "./../../assets/img/bfp_logo.png";
import brand_top from "./../../assets/img/brand_top.png";
import chines from "./../../assets/img/chines.png";
import cipla from "./../../assets/img/cipla.png";
import clearpath from "./../../assets/img/clearpath.png";
import coat_of_arms from "./../../assets/img/coat_of_arms.png";
import dhl_Logo from "./../../assets/img/DHL_Logo.png";
import dog_chine from "./../../assets/img/dog_chine.png";
import ecobank from "./../../assets/img/ecobank.png";
import du from "./../../assets/img/du.png";
import equity_bank from "./../../assets/img/equity_bank.png";
import fika from "./../../assets/img/fika.png";
import firstbank from "./../../assets/img/firstbank.png";
import forsman from "./../../assets/img/forsman.png";
import gcer from "./../../assets/img/gcer.png";
import global_slc from "./../../assets/img/Global_SLC.png";
import hospital from "./../../assets/img/hospital.png";
import imb_LOGO from "./../../assets/img/imb_LOGO.png";
import icbc from "./../../assets/img/icbc.png";
import jumia from "./../../assets/img/jumia.png";
import later from "./../../assets/img/later.png";
import logo_main from "./../../assets/img/logo_main.png";
import maersk from "./../../assets/img/maersk.png";
import mahindra from "./../../assets/img/mahindra.png";
import maniptal from "./../../assets/img/maniptal.png";
import martimesbus from "./../../assets/img/martimesbus.png";
import ministry from "./../../assets/img/ministry.png";
import momo from "./../../assets/img/momo.png";
import norway from "./../../assets/img/norway.png";
import Netcare from "./../../assets/img/Netcare.png";
import niit from "./../../assets/img/niit.png";
import otovo from "./../../assets/img/otovo.png";
import pam_golding from "./../../assets/img/pam_golding.png";
import paytm_logo from "./../../assets/img/paytm_logo.png";
import Q1_Resort from "./../../assets/img/Q1_Resort.png";
import rozetka from "./../../assets/img/rozetka.png";
import schaeffler from "./../../assets/img/schaeffler.png";
import seeff from "./../../assets/img/seeff.png";
import serena_hotel from "./../../assets/img/serena_hotel.png";
import soltech from "./../../assets/img/soltech.png";
import standart_bank from "./../../assets/img/standart_bank.png";
import solar_gaps from "./../../assets/img/solar_gaps.png";
import swiggy from "./../../assets/img/swiggy.png";
import thinkit from "./../../assets/img/thinkit.png";
import visitsweden from "./../../assets/img/visitsweden.png";
import wuerth_group from "./../../assets/img/wuerth_group.png";
import s from "./../../pages/clients/clients.module.scss";

const BannerImages = () => {
  return (
    <div>
      <div className={s.slider}>
        <ul>
          <li>
            <img src={paytm_logo} alt="logo" />
          </li>
          <li>
            <img src={Q1_Resort} alt="logo" />
          </li>
          <li>
            <img src={schlumberger_grey} alt="logo" />
          </li>
          <li>
            <img src={AHG_Logo} alt="logo" />
          </li>
          <li>
            <img src={aspective} alt="logo" />
          </li>
          <li>
            <img src={gcer} alt="logo" />
          </li>
          <li>
            <img src={du} alt="logo" />
          </li>
          <li>
            <img src={fika} alt="logo" />
          </li>
          <li>
            <img src={pharma} alt="logo" />
          </li>
          <li>
            <img src={later} alt="logo" />
          </li>
          <li>
            <img src={equity_bank} alt="logo" />
          </li>
          <li>
            <img src={forsman} alt="logo" />
          </li>
          <li>
            <img src={jumia} alt="logo" />
          </li>
          <li>
            <img src={global_slc} alt="logo" />
          </li>
          <li>
            <img src={imb_LOGO} alt="logo" />
          </li>
          <li>
            <img src={paytm_logo} alt="" />
          </li>
          <li>
            <img src={Q1_Resort} alt="" />
          </li>
          <li>
            <img src={schlumberger_grey} alt="" />
          </li>
          <li>
            <img src={AHG_Logo} alt="" />
          </li>
          <li>
            <img src={aspective} alt="" />
          </li>
          <li>
            <img src={gcer} alt="" />
          </li>
          <li>
            <img src={du} alt="" />
          </li>
          <li>
            <img src={fika} alt="" />
          </li>
          <li>
            <img src={pharma} alt="" />
          </li>
          <li>
            <img src={later} alt="" />
          </li>
          <li>
            <img src={equity_bank} alt="" />
          </li>
          <li>
            <img src={forsman} alt="" />
          </li>
          <li>
            <img src={jumia} alt="" />
          </li>
          <li>
            <img src={global_slc} alt="" />
          </li>
          <li>
            <img src={imb_LOGO} alt="" />
          </li>

          <li>
            <img src={paytm_logo} alt="" />
          </li>
          <li>
            <img src={Q1_Resort} alt="" />
          </li>
          <li>
            <img src={schlumberger_grey} alt="" />
          </li>
          <li>
            <img src={AHG_Logo} alt="" />
          </li>
          <li>
            <img src={aspective} alt="" />
          </li>
          <li>
            <img src={gcer} alt="" />
          </li>
          <li>
            <img src={du} alt="" />
          </li>
          <li>
            <img src={fika} alt="" />
          </li>
          <li>
            <img src={pharma} alt="" />
          </li>
          <li>
            <img src={later} alt="" />
          </li>
          <li>
            <img src={equity_bank} alt="" />
          </li>
          <li>
            <img src={forsman} alt="" />
          </li>
          <li>
            <img src={jumia} alt="" />
          </li>
          <li>
            <img src={global_slc} alt="" />
          </li>
          <li>
            <img src={imb_LOGO} alt="" />
          </li>
        </ul>
      </div>
      <div className={s.slider}>
        <ul>
          <li>
            <img src={seeff} alt="" />
          </li>
          <li>
            <img src={havaslynx} alt="" />
          </li>
          <li>
            <img src={Netcare} alt="" />
          </li>
          <li>
            <img src={onlineresults} alt="" />
          </li>
          <li>
            <img src={clearpath} alt="" />
          </li>
          <li>
            <img src={dhl_Logo} alt="" />
          </li>
          <li>
            <img src={kogan} alt="" />
          </li>
          <li>
            <img src={icbc} alt="" />
          </li>
          <li>
            <img src={aderant} alt="" />
          </li>
          <li>
            <img src={niit} alt="" />
          </li>
          <li>
            <img src={dog_chine} alt="" />
          </li>
          <li>
            <img src={swiggy} alt="" />
          </li>
          <li>
            <img src={ministry} alt="" />
          </li>
          <li>
            <img src={momo} alt="" />
          </li>
          <li>
            <img src={mahindra} alt="" />
          </li>
          <li>
            <img src={seeff} alt="" />
          </li>
          <li>
            <img src={havaslynx} alt="" />
          </li>
          <li>
            <img src={Netcare} alt="" />
          </li>
          <li>
            <img src={onlineresults} alt="" />
          </li>
          <li>
            <img src={clearpath} alt="" />
          </li>
          <li>
            <img src={dhl_Logo} alt="" />
          </li>
          <li>
            <img src={kogan} alt="" />
          </li>
          <li>
            <img src={icbc} alt="" />
          </li>
          <li>
            <img src={aderant} alt="" />
          </li>
          <li>
            <img src={niit} alt="" />
          </li>
          <li>
            <img src={dog_chine} alt="" />
          </li>
          <li>
            <img src={swiggy} alt="" />
          </li>
          <li>
            <img src={ministry} alt="" />
          </li>
          <li>
            <img src={momo} alt="" />
          </li>
          <li>
            <img src={mahindra} alt="" />
          </li>
          <li>
            <img src={seeff} alt="" />
          </li>
          <li>
            <img src={havaslynx} alt="" />
          </li>
          <li>
            <img src={Netcare} alt="" />
          </li>
          <li>
            <img src={onlineresults} alt="" />
          </li>
          <li>
            <img src={clearpath} alt="" />
          </li>
          <li>
            <img src={dhl_Logo} alt="" />
          </li>
          <li>
            <img src={kogan} alt="" />
          </li>
          <li>
            <img src={icbc} alt="" />
          </li>
          <li>
            <img src={aderant} alt="" />
          </li>
          <li>
            <img src={niit} alt="" />
          </li>
          <li>
            <img src={dog_chine} alt="" />
          </li>
          <li>
            <img src={swiggy} alt="" />
          </li>
          <li>
            <img src={ministry} alt="" />
          </li>
          <li>
            <img src={momo} alt="" />
          </li>
          <li>
            <img src={mahindra} alt="" />
          </li>
        </ul>
      </div>
      <div className={s.slider}>
        <ul>
          <li>
            <img src={hospital} alt="" />
          </li>
          <li>
            <img src={brand_top} alt="" />
          </li>
          <li>
            <img src={wuerth_group} alt="" />
          </li>
          <li>
            <img src={maersk} alt="" />
          </li>
          <li>
            <img src={thinkit} alt="" />
          </li>
          <li>
            <img src={fei} alt="" />
          </li>
          <li>
            <img src={rozetka} alt="" />
          </li>
          <li>
            <img src={skov} alt="" />
          </li>
          <li>
            <img src={apolo} alt="" />
          </li>
          <li>
            <img src={schaeffler} alt="" />
          </li>
          <li>
            <img src={bfp_logo} alt="" />
          </li>
          <li>
            <img src={eucon} alt="" />
          </li>
          <li>
            <img src={ecobank} alt="" />
          </li>
          <li>
            <img src={solar_gaps} alt="" />
          </li>
          <li>
            <img src={coat_of_arms} alt="" />
          </li>
          <li>
            <img src={hospital} alt="" />
          </li>
          <li>
            <img src={brand_top} alt="" />
          </li>
          <li>
            <img src={wuerth_group} alt="" />
          </li>
          <li>
            <img src={maersk} alt="" />
          </li>
          <li>
            <img src={thinkit} alt="" />
          </li>
          <li>
            <img src={fei} alt="" />
          </li>
          <li>
            <img src={rozetka} alt="" />
          </li>
          <li>
            <img src={skov} alt="" />
          </li>
          <li>
            <img src={apolo} alt="" />
          </li>
          <li>
            <img src={schaeffler} alt="" />
          </li>
          <li>
            <img src={bfp_logo} alt="" />
          </li>
          <li>
            <img src={eucon} alt="" />
          </li>
          <li>
            <img src={ecobank} alt="" />
          </li>
          <li>
            <img src={solar_gaps} alt="" />
          </li>
          <li>
            <img src={coat_of_arms} alt="" />
          </li>
          <li>
            <img src={hospital} alt="" />
          </li>
          <li>
            <img src={brand_top} alt="" />
          </li>
          <li>
            <img src={wuerth_group} alt="" />
          </li>
          <li>
            <img src={maersk} alt="" />
          </li>
          <li>
            <img src={thinkit} alt="" />
          </li>
          <li>
            <img src={fei} alt="" />
          </li>
          <li>
            <img src={rozetka} alt="" />
          </li>
          <li>
            <img src={skov} alt="" />
          </li>
          <li>
            <img src={apolo} alt="" />
          </li>
          <li>
            <img src={schaeffler} alt="" />
          </li>
          <li>
            <img src={bfp_logo} alt="" />
          </li>
          <li>
            <img src={eucon} alt="" />
          </li>
          <li>
            <img src={ecobank} alt="" />
          </li>
          <li>
            <img src={solar_gaps} alt="" />
          </li>
          <li>
            <img src={coat_of_arms} alt="" />
          </li>
        </ul>
      </div>
      <div className={s.slider}>
        <ul>
          <li>
            <img src={otovo} alt="" />
          </li>
          <li>
            <img src={maniptal} alt="" />
          </li>
          <li>
            <img src={americanas} alt="" />
          </li>
          <li>
            <img src={clvertical} alt="" />
          </li>
          <li>
            <img src={cipla} alt="" />
          </li>
          <li>
            <img src={firstbank} alt="" />
          </li>
          <li>
            <img src={soltech} alt="" />
          </li>
          <li>
            <img src={visitsweden} alt="" />
          </li>
          <li>
            <img src={chines} alt="" />
          </li>
          <li>
            <img src={transaction_network} alt="" />
          </li>
          <li>
            <img src={komplett} alt="" />
          </li>
          <li>
            <img src={amity} alt="" />
          </li>
          <li>
            <img src={norway} alt="" />
          </li>
          <li>
            <img src={martimesbus} alt="" />
          </li>
          <li>
            <img src={logo_main} alt="" />
          </li>
          <li>
            <img src={otovo} alt="" />
          </li>
          <li>
            <img src={maniptal} alt="" />
          </li>
          <li>
            <img src={americanas} alt="" />
          </li>
          <li>
            <img src={clvertical} alt="" />
          </li>
          <li>
            <img src={cipla} alt="" />
          </li>
          <li>
            <img src={firstbank} alt="" />
          </li>
          <li>
            <img src={soltech} alt="" />
          </li>
          <li>
            <img src={visitsweden} alt="" />
          </li>
          <li>
            <img src={chines} alt="" />
          </li>
          <li>
            <img src={transaction_network} alt="" />
          </li>
          <li>
            <img src={komplett} alt="" />
          </li>
          <li>
            <img src={amity} alt="" />
          </li>
          <li>
            <img src={norway} alt="" />
          </li>
          <li>
            <img src={martimesbus} alt="" />
          </li>
          <li>
            <img src={logo_main} alt="" />
          </li>
          <li>
            <img src={otovo} alt="" />
          </li>
          <li>
            <img src={maniptal} alt="" />
          </li>
          <li>
            <img src={americanas} alt="" />
          </li>
          <li>
            <img src={clvertical} alt="" />
          </li>
          <li>
            <img src={cipla} alt="" />
          </li>
          <li>
            <img src={firstbank} alt="" />
          </li>
          <li>
            <img src={soltech} alt="" />
          </li>
          <li>
            <img src={visitsweden} alt="" />
          </li>
          <li>
            <img src={chines} alt="" />
          </li>
          <li>
            <img src={transaction_network} alt="" />
          </li>
          <li>
            <img src={komplett} alt="" />
          </li>
          <li>
            <img src={amity} alt="" />
          </li>
          <li>
            <img src={norway} alt="" />
          </li>
          <li>
            <img src={martimesbus} alt="" />
          </li>
          <li>
            <img src={logo_main} alt="" />
          </li>
        </ul>
      </div>
      <div className={s.slider}>
        <ul>
          <li>
            <img src={imagineer} alt="logo" />
          </li>
          <li>
            <img src={pam_golding} alt="logo" />
          </li>
          <li>
            <img src={biosciences} alt="logo" />
          </li>
          <li>
            <img src={dachser} alt="logo" />
          </li>
          <li>
            <img src={inpas} alt="logo" />
          </li>
          <li>
            <img src={serena_hotel} alt="logo" />
          </li>
          <li>
            <img src={dr_redis} alt="logo" />
          </li>
          <li>
            <img src={konga} alt="logo" />
          </li>
          <li>
            <img src={basecon} alt="logo" />
          </li>
          <li>
            <img src={didi} alt="logo" />
          </li>
          <li>
            <img src={continental} alt="logo" />
          </li>
          <li>
            <img src={thomsonreuters} alt="logo" />
          </li>
          <li>
            <img src={standart_bank} alt="logo" />
          </li>
          <li>
            <img src={nvision} alt="logo" />
          </li>
          <li>
            <img src={gcer} alt="logo" />
          </li>
          <li>
            <img src={imagineer} alt="logo" />
          </li>
          <li>
            <img src={pam_golding} alt="logo" />
          </li>
          <li>
            <img src={biosciences} alt="logo" />
          </li>
          <li>
            <img src={dachser} alt="logo" />
          </li>
          <li>
            <img src={inpas} alt="logo" />
          </li>
          <li>
            <img src={serena_hotel} alt="logo" />
          </li>
          <li>
            <img src={dr_redis} alt="logo" />
          </li>
          <li>
            <img src={konga} alt="logo" />
          </li>
          <li>
            <img src={basecon} alt="logo" />
          </li>
          <li>
            <img src={didi} alt="logo" />
          </li>
          <li>
            <img src={continental} alt="logo" />
          </li>
          <li>
            <img src={thomsonreuters} alt="logo" />
          </li>
          <li>
            <img src={standart_bank} alt="logo" />
          </li>
          <li>
            <img src={nvision} alt="logo" />
          </li>
          <li>
            <img src={gcer} alt="logo" />
          </li>
          <li>
            <img src={imagineer} alt="logo" />
          </li>
          <li>
            <img src={pam_golding} alt="logo" />
          </li>
          <li>
            <img src={biosciences} alt="logo" />
          </li>
          <li>
            <img src={dachser} alt="logo" />
          </li>
          <li>
            <img src={inpas} alt="logo" />
          </li>
          <li>
            <img src={serena_hotel} alt="logo" />
          </li>
          <li>
            <img src={dr_redis} alt="logo" />
          </li>
          <li>
            <img src={konga} alt="logo" />
          </li>
          <li>
            <img src={basecon} alt="logo" />
          </li>
          <li>
            <img src={didi} alt="logo" />
          </li>
          <li>
            <img src={continental} alt="logo" />
          </li>
          <li>
            <img src={thomsonreuters} alt="logo" />
          </li>
          <li>
            <img src={standart_bank} alt="logo" />
          </li>
          <li>
            <img src={nvision} alt="logo" />
          </li>
          <li>
            <img src={gcer} alt="logo" />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default BannerImages;
