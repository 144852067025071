import React from "react";
import styles from "./index.module.css";

//Animtion for the first page
export const Animation = () => {
  return (
    <section
      className={`${styles.promo_area} ${
        styles.promo_area_next || styles.promo_area_back
      }`}
    >
      <div className={styles.planet1}></div>
      <div className={`${styles.promo_area} ${styles.planet}`}>
        <div className={`${styles.round_planet} ${styles.planet2}`}>
          <div className={`${styles.round_planet} ${styles.planet3}`}>
            <div className={styles.spinners}>
              {Array.from({ length: 4 }, (_, i) => (
                <div key={i} className={`${styles.spinner} ${styles[`spinner_${i + 1}`]}`}></div>
              ))}
            </div>
            <div className={`${styles.round_planet} ${styles.planet4}`}></div>
            {Array.from({ length: 4 }, (_, i) => (
              <div key={i} className={`${styles.star} ${styles[`star${i + 1}`]}`}></div>
            ))}
            {Array.from({ length: 4 }, (_, i) => (
              <div key={i} className={`${styles.star} ${styles[`star${i + 5}`]}`}></div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
