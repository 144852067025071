import { useEffect } from "react";

//script for integrating osano cookies service
export function OsanoScript() {
  useEffect(() => {
    const timeout = setTimeout(() => {
      const script = document.createElement("script");
      script.setAttribute("rel", "preconnect");
      script.setAttribute("crossorigin", "");
      script.src =
        "https://cmp.osano.com/AzqafHTgxBswNdF/782d057b-4a1e-438b-b4f0-15f4985283fa/osano.js";
      script.async = true;
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }, 4000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return null;
}
